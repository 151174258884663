<template>
  <div>
    <b-row>
      <b-col cols="12">
        <p class="title">
          LISTADO DE CLIENTES
        </p>
      </b-col>
    </b-row>
    <b-card no-body>
      <base-actions-table
        :columns="fields"
        :records="records"
        :is-processing="isProcessing"
        :module="module"
        @creating="handleCreating"
        @editing="handleEditing"
        @deleting="handleDeleting"
      />
    </b-card>
  </div>
</template>

<script>
import {
	BRow,
	BCol,
	VBTooltip,
	BCard,
} from 'bootstrap-vue'
import BaseActionsTable from '@/components/base/BaseActionsTable'
import CRUDMixin from '@/mixins/CRUDMixin'

export default {
	inject: ['clientRepository'],
	components: {
		BRow,
		BCol,
		BCard,
		BaseActionsTable,
	},
	directives: {
		'b-tooltip': VBTooltip,
	},
	mixins: [CRUDMixin],
	data() {
		return {
			fields: [
				{
					key: 'name',
					label: 'Nombre',
				},
				{
					key: 'nit',
					label: 'Nit',
				},
				{
					key: 'number_central_offices',
					label: 'Número de sedes permitidas',
				},
				{
					key: 'number_users',
					label: 'Número de usuarios permitidos',
				},
				{
					key: 'actions',
					label: 'Acciones',
				},
			],
			records: [],
			module: 'client',
			repository: 'clientRepository',
			isBusy: false,
		}
	},
	async mounted() {
		const me = this
		await me.handleLoadData()
	},
	methods: {
		handleCreating() {
			this.$router.push({ name: 'client-create' })
		},

		handleEditing(record) {
			this.$router.push({ name: 'client-update', params: { uuid: record.id } })
		},

		handleDeleting(record) {
			this.del(record.id)
		},
	},
}
</script>

<style lang="scss" scoped>
.title {
	font-weight: bold;
	font-size: 22px;
}
.iconos {
	display: inline-block;
	margin-right: 2px;
}
</style>
