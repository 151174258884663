<template>
  <div>
    <b-card
      no-body
      class="card-statistics"
    >
      <b-card-body class="statistics-body">
        <b-row class="ml-5">
          <b-col
            v-for="item in statisticsData"
            :key="item.icon"
            xl="3"
            sm="6"
            :class="item.customClass"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <v-icon
                  scale="5"
                  :name="item.icon"
                  :class="`text-${item.color}`"
                />
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-row>
      <b-col cols="8">
        <p class="title">
          LISTADO DE USUARIOS
        </p>
      </b-col>
      <b-col cols="4">
        <router-link to="/clientes/usuarios/RolUsuario">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="success"
            class=" iconos btn-icon rounded-circle ml-1"
          >
            <feather-icon icon="PlusIcon" />
          </b-button></router-link>
      </b-col>
    </b-row>
    <br>
    <b-table
      striped
      hover
      responsive
      :items="servicesData"
      :fields="servicesFields"
      class="mb-1"
      tbody-tr-class="cursor-pointer"
    >
      <template
        #cell(actions)=""
      >
        <b-button
          v-b-tooltip.hover.top="'Detalles'"
          variant="success"
          class="iconos btn-icon mr-20"
        >
          <feather-icon
            icon="EyeIcon"
          />
        </b-button>
        <b-button
          v-b-modal.table
          v-b-tooltip.hover.top="'Editar Servicio'"
          variant="primary"
          class="iconos btn-icon mr-20"
        >
          <feather-icon
            icon="EditIcon"
          />
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
     BCard, BCardText, BCardBody, BRow, BCol, VBTooltip, BMedia, BMediaAside, BMediaBody, BButton, BTable,
} from 'bootstrap-vue'

export default {
  components: {
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BButton,
  BTable,
	},
  directives: {
	'b-tooltip': VBTooltip,
	},
    data() {
      return {
        statisticsData: [
          {
            icon: 'users',
            color: 'primary',
            title: '3',
            subtitle: 'Total de Usuarios',
          },
          {
            icon: 'user-plus',
            color: 'info',
            title: '2',
            subtitle: 'Registrado por Sistema',
          },
          {
            icon: 'address-book',
            color: 'success',
            title: '50',
            subtitle: 'Ingreso de Usuarios x Dia',
          },
        ],
        servicesFields: [
            {
                key: 'Name',
                label: 'Nombre',
            },
            {
                key: 'Users',
                label: 'Usuario',

            },
            {
                key: 'Email',
                label: 'Correo',
            },
            {
                key: 'Cellphone',
                label: 'Celular',
            },
            {
                key: 'Company',
                label: 'Empresa',
            },
            {
                key: 'Rol',
                label: 'Rol',
            },
            {
                key: 'actions',
                label: 'Acciones',
            },
		],
      servicesData: [
          {
              Name: 'Gustavo Castañez',
              Users: 'GustavoCastañez',
              Email: 'GustavoCastañez@gmail.com',
              Cellphone: '3213412897',
              Company: 'Helpmedica',
              Rol: 'Gerente',
          },
          {
              Name: 'Jesus Torres',
              Users: 'JesusTor',
              Email: 'JesusTor@gmail.com',
              Cellphone: '3001234567',
              Company: 'Helpmedica',
              Rol: 'Practicante',
          },
          {
              Name: 'Rafael Quintero',
              Users: 'RafaelQuintero',
              Email: 'RafaQuint@gmail.com',
              Cellphone: '3152789346',
              Company: 'Inversiones Castañez',
              Rol: 'Enfermero',
          },
        ],
      }
    },
}
</script>

<style lang="scss" scoped>
 .title {
    font-weight: bold;
    font-size: 22px;
 }
 .iconos{
   display: inline-block;
   margin-right: 2px;
 }
</style>
